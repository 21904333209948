import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {navInit} from './scripts/navigation';
import {slickInit} from './scripts/slickInit';



fontawesome.library.add(far, fab, fas);

navInit();
slickInit();

jQuery(document).ready(function ( $ ) {
  // smooth scroll to ID
  $('.menu-item a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top - 120 // choose what you need
    }, 500, 'linear');
  });
});

